<template>
    <div>
        <h1 class="page-title">{{ this.$route.meta.title }}</h1>
        <iframe :src="mapSrc" width="1160" height="1160"></iframe>
<!--        <span class="scrollToTop"><inline-svg :src="require('@/assets/images/ic_next.svg')" /></span>-->
    </div>
</template>

<script>
export default {
	name: "InfoMap",
	data() {
		return {};
	},
	computed: {
		mapSrc: function () {
			return this.$route.meta.mapSrc;
		}
	},
	methods: {}
};
</script>

<style scoped>

iframe {
    border: 0;
    max-height: calc(100vh - 240px);
}

@media (max-width: 1024px) {
    .page-title {
        display: none;
    }

    iframe {
        margin: 0 -16px;
        max-height: calc(100vh - 75px);
        max-width: 100vw;
    }
}

.scrollToTop{
    position: fixed;
    bottom: 96px;
    right: 16px;
    z-index: 50;
    background: var(--color-primary);
    border-radius: 50%;
    transform: rotate(-90deg);
    padding:8px;
}
.scrollToTop svg {
    width: 20px;
    height: 20px;
    fill: var(--color-white);
}
</style>